.damage-form-container {
	width: 90%;
	border-radius: 2px;
	background: #f7f7f7;
	margin: 1em 0;
	box-shadow: 0 3px 7px #474342a3;
	padding-bottom: 3rem;

	.field,
	.radio-field {
		flex: 50%;
		max-width: 50%;
		min-height: 5.2rem;

		.p-error {
			font-size: 14px;
		}

		padding-bottom: 15px;
	}

	form {
		width: 100%;
	}

	.p-component {
		width: 100%;
	}

	.submit-btn {
		display: flex;
		width: auto;

		&.loading {
			background-image: url('./../../images/tail-spin.svg');
			background-repeat: no-repeat;
			background-size: 1.5em;
			background-position: 0.5em;
			opacity: 0.75;
		}
	}
	    .clear-btn {
		display: flex;
		width: auto;
		padding: 10px 50px;
	}

	.p-datepicker {
		width: auto;
	}

	.leaflet-container {
		height: 640px;
		max-height: 70vh;
		margin-bottom: 1rem;
		width: 100%;
	}

	.top-container,
	.bottom-container {
		width: 100%;

		.address-container,
		.map-container {
			width: 100%;
			flex-wrap: wrap;

			.field {
				flex: 1 1 20%;

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					margin-right: 15px;
				}

				&:nth-child(2),
				&:nth-child(3) {
					flex: 1 1 2em;
				}

			}
		}

		.address-container {
			padding: 0 2rem;
		}
	}

	.section {
		padding: 0 2rem;
		width: 100%;

		.field,
		.radio-field {
			&:nth-child(odd) {
				padding-right: 15px;
			}

			&:nth-child(even) {
				padding-left: 15px;
			}
		}

		.half-section {
			.field {
				padding: 0 0 15px 0;
				max-width: 100%;
			}
		}
	}

	.section-triple {

		.field,
		.radio-field {
			flex: 35% 1;
			padding-right: 15px;

			&:nth-child(3) {
				flex: 25%;
				padding: 0 0 0 15px;
			}
		}
	}

	.inline-section {
		.custom-field {
			flex: 50%;
			max-width: 100px;
			padding-top: 1.2rem;
		}

		span {
			margin-left: 3px;
		}

		.radio-field {
			flex: initial;
		}
	}

	.section-title {
		width: 100%;

		h3 {
			padding: 0 2rem;
		}
	}

	.select-field {
		background: #f8f9fa;
		border: 1px solid #ced4da;
		border-radius: 3px;
		font-size: 1rem;
		color: #495057;
		padding: 0.5rem 0.5rem;
		align-items: center;

		&:focus {
			box-shadow: 0 0 0 0.2rem #a6d5fa;
			border-color: #2196F3;
			outline: 0;
			outline-color: transparent;
			outline-style: none;
		}

		&.empty {
			background-color: #ffffff;
			color: #d4d4d4;
		}
	}

	.empty {
		&.p-inputtext {
			background-color: #ffffff;
		}

		.p-radiobutton .p-radiobutton-box {
			background-color: #ffffff;
		}

		&.p-checkbox>.p-checkbox-box {
			background-color: #ffffff;

		}
	}

	.p-inputtext.p-invalid.p-component {
		&:focus {
			box-shadow: 0 0 0 0.2rem #f44336a3;
		}
	}

	.p-calendar .p-inputtext {
		text-overflow: ellipsis;
	}

	.red-span {
		color: #f44336;
	}

	.p-inputtextarea {
		resize: none;
	}
}

.header {
	height: 72px;
	width: 100%;
	text-align: center;

	h2 {
		color: black;
	}
}

.dialog-container {
	background: white;

	form {
		width: 100%;

		input {
			width: 100%;
		}
	}
}

.warning-dialog {
	width: 400px;
	max-width: 90%;
}

.top-controls {
	width: 100%;
	display: flex;
	padding: 0.5em 0;
	background-color: white;
	box-shadow: 1px 1px 2px #0005;
	position: sticky;
	top: 0;
	z-index: 1010;
}
.top-control-center {
	flex: 1;
	text-align: center;
	line-height: 2.25em;
}
.top-control-left,
.top-control-right {
	width: 10em;
}
.top-control-right {
	text-align: right;
}

@media screen and (max-width: 600px) {
	.form-container {
		width: 90%;
		max-height: 90%;
		border-radius: 10px;
		min-height: 500px;
	}

	.damage-form-container {
		.field {
			min-width: none;
			flex: 100%;
		}

		.bottom-container {

			.field,
			.radio-field {

				&:nth-child(odd),
				&:nth-child(even) {
					padding: 10px;
				}
			}
		}
	}

	.header {
		font-size: 14px;
		padding-left: 20px;
		text-align: left;
	}
}