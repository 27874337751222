#root {
	background-image: url(../../images/background.png);
	min-height: 100vh;
}

.prompt-container {
	background-image: url(../../images/login-background.png);
	background-size: cover;
	position: fixed;
	bottom: 0;
	width: 100vw;
	background-color: #3a302e;
	color: #ffffff;
	display: flex;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 1em 5em;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 9999;
	height: 100vh;
	flex-direction: column;
}

.prompt-inner {
	display: flex;
	flex-direction: column;
	background-color: #000d;
	height: 20em;
	width: 24em;
	padding: 2em 2em 4em;
}

.prompt-text {
	flex: 1;
	font-size: 1.5em;
}

.update-container {
	position: fixed;
	bottom: 0;
	width: 100vw;
	background-color: #3a302e;
	color: #ffffff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em 5em;
	box-sizing: border-box;
	z-index: 999;
}

.update-button {
	text-align: center;
	font-size: 1.25em;

	margin-top: 2em;
	background-color: #2196f3;
	padding: 0.5em 1em;
	border-radius: 0.25em;
	cursor: pointer;
}

.install-prompt-container {
	// background-image: url(../../images/background-login.jpg);
	background-size: cover;
	position: fixed;
	top: 1em;
	left: 1em;
	width: 20em;
	background-color: #3a302e;
	color: #ffffff;
	display: flex;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 1em;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	// height: 100vh;
	flex-direction: column;
}

.prompt-actions {
	width: 100%;
	display: flex;
}

.close-button,
.install-prompt-button {
	padding: 1em;
	color: white;
	border: none;
	flex: 1;
}

.close-button {
	background-color: #332e2a;
}

.install-prompt-button {
	margin-left: 1em;
	background-color: #935140;
}

.language-bar {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 36px;
	position: absolute;
	top: 18px;
	right: 10px;

	.language-icon {
		cursor: pointer;
		opacity: 0.6;

		&.active {
			cursor: pointer;
			opacity: 1;
			width: 48px;
		}
	}
}