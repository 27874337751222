.image-picker-outer-container {
	.p-dialog-header {
		padding: 0 0.25rem;
	}
	.p-dialog-content {
		padding: 0 0.5rem;
	}
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	min-width: 100%;
}

.image-picker-categories {
	position: relative;
	top: 2px;
	display: flex;

	button {
		font-size: 1rem;
		max-width: 10em;
		flex: 1;
		padding: 1em;
		background: #f7f7f7;
		border: 1px solid #ced4da;
		border-right: none;
		border-top-right-radius: 1em;
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

		&:last-child {
			border-right: 1px solid #ced4da;
		}
	}

	.active {
		background: #fff;
		border-bottom-color: transparent;
		box-shadow: 0px -2px 5px -3px #00000073;
		position: relative;
		z-index: 1;
	}
}

.capture-image {
	display: none;
}

.categorized-image-picker {
	width: 100%;
	padding: 1em 2em;
}

.icons-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 1em 0;
	position: relative;
}

.icon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: .5em;
}

.icon-caption {
	color: #2196f3;
	font-size: 0.75rem;
	margin-top: 0.5em;
}