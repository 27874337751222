.grid-operators-container {
	width: 95%;
	.grid-operator {
		width: calc(25% - 20px);
		margin: 10px;
		text-align: center;

		.p-card .p-card-body, .p-card-content {
			padding: 0;
		}
		.image-container {
			height: 200px;
			padding: 0.5rem;
		}
		.logo {
			max-width: 100%;
			max-height: 100%;
		}
		.image-top {
			height: calc(100% - 3rem);
		}
		.grid-name {
			line-height: 2;
			font-size: 1rem;
			font-weight: 700;
		}
	}
}
@media screen and (max-width: 1024px) {
	.grid-operators-container {
		.grid-operator {
			width: calc(33% - 20px);
		}
	}
}
@media screen and (max-width: 600px) {
	.grid-operators-container {
		.grid-operator {
			width: calc(50% - 20px);
		}
	}
}
@media screen and (max-width: 480px) {
	.grid-operators-container {
		.grid-operator {
			width: 100%;
		}
	}
}