.dropdown-container{
	position: relative;
}
.listbox{
    width: calc(100% - 15px);
    margin: 0;
    z-index: 1000;
    position: absolute;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	top:4.25rem;
	padding: 0;
	&:focus{
			border-color: none;
			outline: 0;
			outline-color: transparent;
			outline-style: none;
		}
	li {
		list-style-type: none; 
		padding: 0;
  		margin: 0; 
		padding:0 5px;
		line-height: 2;
	}
}
.highlighted {
	background-color: #bde4ff;
}