.image-visualization {
	background: #fff;
	border: 1px solid #ced4da;
	border-radius: 3px;
	display: flex;
	overflow-x: auto;
	align-items: center;
	width: 100%;
	height: 10em;
	box-sizing: border-box;
}

.image-visualization .image {
	max-width: 10em;
	max-height: 6em;
	height: auto;
	object-fit: contain;
	display: block;
}

.image-scroll-left {
	display: flex;
	align-items: center;
	flex: 1;
}

.camera-input-label {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #2196f31a;
	color: #2196f3;
	padding: 1em;
	cursor: pointer;

}

.not-selected-image {
	height: 6em;
	width: 10em;
	max-width: 100%;
	margin: 1.5em;
}

.not-selected-image .inner {
	background-color: #e5ebf3;
	height: 100%;
}

.img-selected {
	position: relative;
	box-shadow: 0 0 7px #474342a3;
	margin: 1.5em 0 1.5em 1.5em;
	display: block;

	&:last-of-type {
		margin: 1.5em;
	}
}

.img-selected {
	.pi-times {
		position: absolute;
		top: -0.5em;
		right: -0.5em;
		background: white;
		border-radius: 50%;
		box-shadow: 0 0 7px #474342a3;
		padding: 3px;

		.icon {
			font-size: 0.8em;
		}
	}
}