.main-container {
	background-image: url(../../images/login-background.png);
	height: 100vh;
	background-size: cover;
}

.form-container {
	width: 60%;
	max-width: 450px;
	// height: 540px;
	border-radius: 10px;
	background-color: #2d2424c7;
	color: white;

	h1 {
		color: white;
	}
}

.field {
	width: 100%;
	min-height: 4.5rem;
}

form {
	width: 80%;
}

.login-btn {
	display: flex;
	width: 100%;
}

.error-message {
	color: #ef8c8c;
}

.span-margin {
	margin-top: 2em;
	color: #f8f9fab3;
	font-size: 0.8em;
}

.login-image {
	width: 100px;
	margin: 15px 0;
}

@media screen and (max-width: 760px) {
	.form-container {
		width: 90%;
		max-height: 90%;
		border-radius: 10px;
		min-height: 540px;
	}

	.field {
		min-width: auto;
		width: 100%;
	}
}